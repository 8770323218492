import { Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader";
import SidebarLayout from "./layouts/SidebarLayout";
import AdminAuthMiddleware from "./middleware/AdminAuthMiddleware";
import AuthMiddleware from "./middleware/AuthMiddleware";
import { adminRouters, publicRouters, signupRouters } from "./router/router";

export default function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* public routers */}
          {publicRouters.map(({ path, element: Element }, idx) => (
            <Route
              path={path}
              key={idx + path}
              element={
                <Suspense fallback={<Loader />}>
                  <Element />
                </Suspense>
              }
            />
          ))}

          {signupRouters.map(({ path, element: Element }, idx) => (
            <Route
              path={path}
              key={idx + path}
              element={
                <AuthMiddleware>
                  <Suspense fallback={<Loader />}>
                    <Element />
                  </Suspense>
                </AuthMiddleware>
              }
            />
          ))}

          {/* Auth routers */}
          <Route path="admin" element={<SidebarLayout />}>
            <Route path="" element={<Navigate to="/admin/dashboard" />} />
            {adminRouters.map(({ path, element: Element }, idx) => (
              <Route
                path={path}
                key={idx + path}
                element={
                  <AdminAuthMiddleware>
                    <Suspense fallback={<Loader />}>
                      <Element />
                    </Suspense>
                  </AdminAuthMiddleware>
                }
              />
            ))}
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}
