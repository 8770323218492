import { lazy } from "react";
const Home = lazy(() => import("../pages/Home"));
const Instruction = lazy(() => import("../pages/Instruction/Instruction"));
const Questions = lazy(() => import("../pages/questions/Questions"));
const CalculateTotal = lazy(() =>
  import("../pages/CalculateTotal/CalculateTotal")
);
const CalculateResult = lazy(() =>
  import("../pages/CalculateTotal/CalculateResult")
);
const SignUp = lazy(() => import("../pages/Signup/SignUp"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Login = lazy(() => import("../pages/Dashboard/Login"));

// public routers
export const publicRouters = [
  {
    path: "/",
    element: Home,
  },
  {
    path: "/signup",
    element: SignUp,
  },
  {
    path: "/login",
    element: Login,
  },
];

export const signupRouters = [
  {
    path: "/instruction",
    element: Instruction,
  },
  {
    path: "/questions",
    element: Questions,
  },
  {
    path: "/calculate-total",
    element: CalculateTotal,
  },
  {
    path: "/calculate-total-result",
    element: CalculateResult,
  },
];

// admin routers
export const adminRouters = [
  {
    path: "dashboard",
    element: Dashboard,
  },
];
