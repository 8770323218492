import { Fragment } from "react";
import { Navigate } from "react-router-dom";
// import useSignupData from "../hooks/useSignupData";

export default function AuthMiddleware({ children }) {
  // const { signupData } = useSignupData();

  return true ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Navigate to="/signup" replace />
  );
}
