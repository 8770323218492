import { Fragment } from "react";
import { Navigate } from "react-router-dom";

export default function AdminAuthMiddleware({ children }) {
  return true ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Navigate to="/signup" replace />
  );
}
