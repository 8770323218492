export const removeEmpty = (arr = []) => {
  return arr.filter((el) => el);
};

export const stringToArray = (text, splitText = " ") => {
  return removeEmpty(text.split(splitText));
};

export const activePathString = (path, splitText = "/", isDynamic) => {
  const pathArray = stringToArray(path, splitText);
  // isDynamic ? pathArray?.length - 1 :
  return pathArray.slice(1, 2).join("/");
};

export const pathString = (path, splitText = "/") => {
  return stringToArray(path, splitText).slice(1, 3).join("/");
};
