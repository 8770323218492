import {
  AppstoreOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Layout, Menu, theme } from "antd";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
// import withSidebar from "../HOC/withSidebar";
// import MainLogo from "../assets/images/logo.svg";
// import Image from "../components/ui/Image";
// import Loader from "../components/ui/Loader";
// import ProfileMenu from "../components/ui/ProfileMenu";
// import { APP_NAME } from "../helpers/config";
import withSidebar from "../HOC/withSidebar";
import SmallLogo from "../assets/logo/fabicon.svg";
import Logo from "../assets/logo/logoDark.svg";
import { activePathString, pathString } from "../utils/array";

const { Header, Sider, Content } = Layout;

const SidebarLayout = ({
  isMobile,
  collapsed,
  openSidebar,
  openMenuKeys,
  handleOpenMenu,
  handleCollapsed,
  handleShowSidebar,
  handleCloseSidebar,
}) => {
  let dynamicParams = useParams();
  const location = useLocation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dynamicPath = Object.keys(dynamicParams || {})?.length;

  function getItem(label, path, icon, children, roles) {
    return {
      key: pathString(path),
      icon,
      children,
      label: children?.length ? label : <Link to={path}>{label}</Link>,
      roles,
    };
  }

  const menuItems = [
    getItem("Dashboard", "/admin/dashboard", <AppstoreOutlined />),
  ];

  return (
    <Layout className="min-h-screen">
      {isMobile ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={handleCloseSidebar}
          open={openSidebar}
          key="left"
          contentWrapperStyle={{ width: "auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <Sider
            trigger={null}
            theme="dark"
            collapsible
            collapsed={false}
            className="!overflow-auto !h-screen custom-sidebar"
            width={250}
          >
            <div className="h-16 flex justify-center items-center">
              <Link to="/admin/dashboard">
                <img className="h-8" src={Logo} alt="" />
                {/* <Image width={55} src={MainLogo} alt={APP_NAME} /> */}
              </Link>
            </div>

            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[
                activePathString(
                  location.pathname,
                  "/",
                  dynamicPath ? true : false
                ),
              ]}
              openKeys={openMenuKeys}
              onOpenChange={handleOpenMenu}
              items={menuItems}
              className="sidebar-menu-item"
            />
          </Sider>
        </Drawer>
      ) : (
        <Sider
          trigger={null}
          theme="dark"
          collapsible
          collapsed={collapsed}
          className="!overflow-auto !h-screen !sticky top-0 custom-sidebar"
          width={250}
        >
          <div className={`pt-5 pb-6 ${collapsed ? "text-center" : ""}`}>
            <Link to="/admin/dashboard">
              <div className="flex justify-center">
                {collapsed ? (
                  <img className="h-8" src={SmallLogo} alt="" />
                ) : (
                  <img className="h-8" src={Logo} alt="" />
                )}
              </div>
            </Link>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[
              activePathString(
                location.pathname,
                "/",
                dynamicPath ? true : false
              ),
            ]}
            openKeys={openMenuKeys}
            onOpenChange={handleOpenMenu}
            items={menuItems}
            className="sidebar-menu-item"
          />
        </Sider>
      )}

      <Layout className="site-layout transition-all">
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className="flex items-center max-h-full justify-between pr-5">
            <div className="w-16 h-16 flex justify-center items-center">
              {isMobile ? (
                <Button
                  type="text"
                  icon={<MenuOutlined />}
                  onClick={handleShowSidebar}
                  className="flex justify-center items-center"
                />
              ) : (
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={handleCollapsed}
                  className="flex justify-center items-center"
                />
              )}
            </div>
          </div>
        </Header>

        <Content
          style={{
            margin: 20,
            padding: "16px 20px",
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withSidebar(SidebarLayout);
